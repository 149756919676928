h4 {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

.cardSection {
  height: 100%;
  display: grid;
}

@media screen and (min-width: 600px) {
  .cardSection {
    margin-top: 30px;
    column-gap: 30px;
    row-gap: 30px;
  }
}

@media screen and (min-width: 800px) {
  .cardSection {
    grid-template-columns: repeat(2, 0.25fr);
  }
}

@media screen and (min-width: 1200px) {
  .cardSection {
    grid-template-columns: repeat(3, 0.25fr);
  }
}

.artistCard {
  position: relative;
  cursor: pointer;
  background-color: beige;
}

@media screen and (min-width: 600px) {
  .artistCard {
    width: 375px;
    height: 375px;
  }
}

.artistImage {
  max-width: 100%;
}

.artistTitle {
  position: absolute;
  bottom: 0px;
  text-align: left;
  font-family: 'Roboto Flex';
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-self: flex-end;
  letter-spacing: 0.05em;
  color: #FEFDF0;
}

.associatedAct_list {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

@media screen and (min-width: 600px) {
  .associatedAct_list {
    width: 375px;
  }
}

.associatedAct_list > * {
  padding: 5px;
}

.associatedAct {
  
  position: relative;
  background-color: #031E1D;
  color: #FEFDF0;
  border-bottom: solid 1px #FEFDF0;
  display: flex;
  flex-direction: column;
}

.associatedAct > * {
  padding: 2px 0;
}

.associatedAct_arrow {
  color: #FEFDF0;
  position: absolute;
  right: 2px;
  font-size: 2.5em;
  top: 50%;
  transform: translateY(-50%);
}

.associatedAct_event {
  font-size: 0.85em;
}

.associatedAct_description {
  font-size: 0.65em;
}

.associatedAct_intro {
  background-color: #FEFDF0;
  color: #031E1D;
  border-top: solid 1px #031E1D;
  font-size: 0.8rem;
}

.associatedAct_title {
  font-size: 1.1em;
  font-weight: 500;
  margin-block-start: 0;
  margin-block-end: 0;
}