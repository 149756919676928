.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media screen and (min-width: 600px) {
  .App-header {
    background-image: url("../public/soundcheck-background.png");
    background-repeat: repeat-y;
    background-size: 100%;
    background-attachment: fixed;
  }
}

.banner {
  width: 100%;
}